import { getAccountStructure } from '@tf/shared';

import { useAccountParams, useSelectedAccount, useSelectedAccountTasks } from '@/core/hooks';

export const useSelectedAccountStructureWithTasks = () => {
	const account = useSelectedAccount();
	const { entityId } = useAccountParams();

	const accountTasks = useSelectedAccountTasks();
	const accountStructure = getAccountStructure(account, entityId);

	accountStructure.nodes.forEach((node) => {
		const entityId = node.data.graphId;

		node.data.tasks = accountTasks.flatMap((r) => {
			return r.entities.find((e) => e.entityId === entityId)?.tasks ?? [];
		});
	});

	return accountStructure;
};

import React from 'react';

import { enableState } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { TFGlobalErrorBoundary } from '@tf/shared';
import { DGLayout } from '@tf/ui';

import { AmplitudeInitialization, AppProvider, SentryInitialization } from '@/core/providers';

import { App } from './App';

enableState();

(window as any).APP_VERSION = import.meta.env.VITE_APP_VERSION;

createRoot(document.getElementById('root') as HTMLElement).render(
	<Sentry.ErrorBoundary
		beforeCapture={(scope) => {
			scope.setTag('location', 'global');
		}}
		fallback={() => <TFGlobalErrorBoundary email="support@trustform.io" />}
	>
		<DGLayout>
			<AppProvider>
				<SentryInitialization />
				<AmplitudeInitialization />
				<App />
			</AppProvider>
		</DGLayout>
	</Sentry.ErrorBoundary>
);

import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';

import { getOverviewQuery } from '@/core/queries';

const overviewAccountsQueryOptions = (
	page: number,
	limit: number,
	params?: Omit<BackendTypes.GetOverviewParams, 'page' | 'limit'>
) => {
	return queryOptions({
		queryKey: ['overviewAccounts', page, limit, params],
		queryFn: () => getOverviewQuery({ ...params, page, limit }),
		staleTime: 20 * 1000,
		select: (overview) => overview.accounts,
	});
};

export const useOverviewAccounts = (
	page: number,
	limit: number,
	params?: Omit<BackendTypes.GetOverviewParams, 'page' | 'limit'>
) => {
	return useQuery(overviewAccountsQueryOptions(page, limit, params));
};

export const overviewAccountQueryOptions = (accountId: number) => {
	return queryOptions({
		queryKey: ['overviewAccount', accountId],
		queryFn: () => getOverviewQuery({ page: 1, limit: 10, accountId }),
		staleTime: 20 * 1000,
		select: ({ accounts, listUsers }) => ({ account: accounts.items[0], listUsers }),
	});
};

export const useOverviewAccount = (accountId: number) => {
	return useQuery(overviewAccountQueryOptions(accountId));
};

const overviewDataQueryOptions = () => {
	return queryOptions({
		queryKey: ['overviewData'],
		queryFn: () => getOverviewQuery({ page: 1, limit: 10 }),
		staleTime: 40 * 1000,
		select: ({ listUsers, tenantNodeId }) => ({ listUsers, tenantNodeId }),
	});
};

export const useOverviewData = () => {
	return useQuery(overviewDataQueryOptions());
};

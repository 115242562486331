import React from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import {
	extractForms,
	getFormHash,
	getFormStatuses,
	getShareholderOwnershipInfo,
	isFormDecisionRequired,
} from '@tf/shared';
import { S } from '@tf/utils';

import { useAccountParams } from '@/core/hooks';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import {
	ConnectionMenu,
	EntityKindIcon,
	FormStatusIndicator,
	SideMenu,
	SideMenuItem,
} from '@/components/shared';

interface Props {
	item: BackendTypes.EntityConnectionData;
	review: ExtendedReviewContainer;
	parentEntity: BackendTypes.Entity;
	connectionKind: string;
	isExternal: boolean;
}

export const Item: React.FC<Props> = ({ item, review, parentEntity, connectionKind, isExternal }) => {
	const params = useAccountParams();
	const navigate = useNavigate();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	const identities = [...item.listSegments, ...parentEntity.listSegments]
		.map((e) => e.SegmentIdentity)
		.filter((i) => i.graphId === item.graphNodeId || i.graphId === item.graphLinkId);
	const forms = extractForms({ formDefs, segmentIdentities: identities });
	const { name, entityKind } = review.listEntities.find((e) => e.graphNodeId === item.graphNodeId)!;

	const basePath = [
		'/accounts',
		params.accountId,
		S.slugify(params.connectionKind),
		review.graphLinkId,
	].join('/');
	const navigateToForm = (form: ExtendedFormDefinition<BackendTypes.FormDefinition>) => {
		window.scrollTo(0, 0);
		const hash = getFormHash(form);
		if (isExternal) {
			return navigate(`${basePath}/entity/${parentEntity.graphNodeId}/${form.name}?hash=${hash}`);
		}
		return navigate(`${basePath}/${form.name}?hash=${hash}`);
	};
	const navigateToEntity = () => {
		window.scrollTo(0, 0);
		return navigate(`${basePath}/entity/${item.graphNodeId}`);
	};

	const shareholderOwnershipInfo = getShareholderOwnershipInfo(
		connectionKind,
		review.data,
		item.graphLinkId
	);
	const label = `${name || 'Unknown'}${
		shareholderOwnershipInfo ? ` (${shareholderOwnershipInfo.OwnershipPercentage}%)` : ''
	}`;

	return (
		<SideMenu>
			<SideMenuItem
				label={label}
				leftSection={<EntityKindIcon kind={entityKind} />}
				onClick={navigateToEntity}
				rightSection={
					<ConnectionMenu
						connectionKind={connectionKind}
						entityId={item.graphNodeId}
						parentEntityId={parentEntity.graphNodeId}
					/>
				}
			/>

			{forms.map((f) => {
				const status = getFormStatuses({
					containers: review.listSegmentReviewContainers,
					segmentIdentities: f.segmentIdentities,
				});

				let isSelected = false;
				if (params.formKind === f.name && params.searchParams.hash) {
					const hashIds = params.searchParams.hash.split('-').map(Number);
					const graphIds = f.segmentIdentities.map((e) => e.graphId);
					isSelected = hashIds.every((e) => graphIds.includes(e));
				}

				return (
					<SideMenuItem
						key={`${review.connectionKind}.${item.graphNodeId}.${f.name}`}
						label={f.label}
						isSelected={isSelected}
						onClick={() => navigateToForm(f)}
						rightSection={
							<FormStatusIndicator
								status={status}
								reviewState={review.state}
								isDecisionRequired={isFormDecisionRequired(f.segmentIdentities, review)}
							/>
						}
					/>
				);
			})}
		</SideMenu>
	);
};

import React, { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import type { FilterItem } from '@tf/ui';
import {
	Avatar,
	Box,
	Center,
	createStyles,
	FilterButton,
	Flex,
	Icon,
	SearchInput,
	Stack,
	TFLoadingOverlay,
	TFLogo,
	TFText,
	Timeline,
	Title,
	Tooltip,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useAccountEventsLog } from '@/core/api/accountEvenstLog';
import { useOverviewAccount } from '@/core/api/overview';
import { useAccountParams } from '@/core/hooks';
import { getDisplayName } from '@/core/utils';

const ACCOUNT_STATUS_MESSAGES: Record<string, string> = {
	ACCOUNT_CREATED: 'has created the account',
	ACCOUNT_CLOSED: 'has closed the account',
	ACCOUNT_ACTIVATED: 'has activated the account',
	ACCOUNT_REJECTED: 'has rejected the application',
	ACCOUNT_SUSPENDED: 'has suspended the account',
	ACCOUNT_USER_ASSIGNED: 'has assigned a new manager to the account',
	ACCOUNT_USER_UNASSIGNED: "has cancelled the manager's assignment from the account",
	REVIEW_PENDING: 'sent the account to compliance review',
	REVIEW_UPDATE_REQUIRED: 'requested more information to complete account review',
	REVIEW_VERIFIED: 'verified the account',
	REVIEW_COMMENTED: 'added new comment',
	KYC_REVIEW_DATE_REACHED: 'is awaiting an overdue KYC re-verification',
	CLIENT_CASE_REQUEST_SENT: 'has opened a new case',
	CLIENT_CASE_REQUEST_REVOKED: 'has revoked the case',
	CLIENT_CASE_REQUEST_VIEWED: 'has viewed the case',
	CLIENT_CASE_REQUEST_UPDATED: 'has updated the case',
	CLIENT_CASE_REQUEST_CLOSED: 'has closed the case',
	CRA_CALCULATED: 'has calculated the customer risk rating',
	USER_INVITATION_REVOKED: 'has revoked the user invitation',
	ACCOUNT_ARCHIVED: 'has archived the account',
};

const availableFilters = [
	{
		name: 'event',
		label: 'Events',
		type: 'select',
		value: Object.entries(ACCOUNT_STATUS_MESSAGES).map(([key, value]) => ({
			label: value,
			value: key,
		})),
	},
	{
		name: 'date',
		label: 'Date range',
		type: 'date',
		value: [null, null],
	},
];

const useFilteredEvents = (events?: BackendTypes.AccountEventLogData) => {
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState<{ id: string; value: unknown }[]>([]);

	if (!events) return;
	const filteredEvents = events.filter((event) => {
		if (!filters.length && !searchValue) return true;

		const eventFilters = filters.find((f) => f.id === 'event');
		const dateFilters = filters.find((f) => f.id === 'date');

		let matchesEventKind = true;
		let matchesDateRange = true;
		let matchesSearch = true;

		if (eventFilters) {
			matchesEventKind = eventFilters.value === event.eventKind;
		}

		if (dateFilters && Array.isArray(dateFilters.value)) {
			const [startDate, endDate] = dateFilters.value.map((dateStr) => new Date(dateStr));
			const eventDate = new Date(event.createdAt);
			eventDate.setHours(0, 0, 0, 0);
			matchesDateRange = eventDate >= startDate && eventDate <= endDate;
		}

		if (searchValue) {
			const eventLabel = ACCOUNT_STATUS_MESSAGES[event.eventKind]?.toLowerCase();
			matchesSearch = eventLabel?.includes(searchValue.toLowerCase());
		}

		return matchesEventKind && matchesDateRange && matchesSearch;
	});

	return { filteredEvents, searchValue, setSearchValue, filters, setFilters };
};

const useStyles = createStyles(() => ({
	timelineItem: {
		'& > :not(:first-of-type)': {
			marginTop: '20px !important',
		},
		'& > ::before': {
			bottom: 'calc(20px * -1) !important',
		},
	},
}));

export const AccountEventLog = () => {
	const { accountId } = useAccountParams();

	const { data: events, isPending: isEventsLogPending } = useAccountEventsLog(accountId);
	const { data } = useOverviewAccount(accountId);
	const listUsers = data?.listUsers;

	const sortedEvents = events?.slice().sort((a, b) => {
		const createdAtA = new Date(b.createdAt).getTime();
		const createdAtB = new Date(a.createdAt).getTime();
		if (a.eventKind !== 'ACCOUNT_CREATED' && b.eventKind === 'ACCOUNT_CREATED') {
			return -1;
		}
		return createdAtA - createdAtB;
	});
	const eventsFilters = useFilteredEvents(sortedEvents);
	const { classes } = useStyles();

	if (isEventsLogPending || !eventsFilters || !listUsers) {
		return <TFLoadingOverlay h={300} size={24} />;
	}

	const { setSearchValue, filteredEvents, setFilters, filters, searchValue } = eventsFilters;

	return (
		<Box p={'1rem'}>
			<Box mb=".75rem">
				<Flex m="0 0 1.5rem">
					<SearchInput searchValue={searchValue} onSearchChange={(v) => setSearchValue(v)} />
					<FilterButton
						popoverPosition={'top-end'}
						availableFilters={availableFilters as FilterItem[]}
						activeFilters={filters}
						setFilters={setFilters}
					/>
				</Flex>
			</Box>

			<Timeline
				className={classes.timelineItem}
				bulletSize={28}
				lineWidth={2}
				styles={{ itemBody: { paddingLeft: '12px !important' } }}
			>
				{!filteredEvents.length && (
					<Center h={300}>
						<Stack align="center" gap="sm">
							<Title order={4} c="dimmed">
								No events found
							</Title>
						</Stack>
					</Center>
				)}
				{filteredEvents.map((event, index) => {
					const { icon, name, userExists } = getTimelineData(event.eventKind, listUsers, event.authorId);
					return (
						<Timeline.Item
							key={index}
							bullet={icon}
							title={
								<TFText lh="normal" size="14px" fw="500">
									{name}
									<TFText lh="normal" component="span" c="#6B7280" m="0 4px" size="14px">
										{ACCOUNT_STATUS_MESSAGES[event.eventKind] || S.prettify(event.eventKind)}
									</TFText>
									{!userExists && (
										<Box display="inline-block" style={{ verticalAlign: 'middle' }}>
											<Tooltip
												label="User information for this event isn’t currently available"
												position="top"
												withArrow
											>
												<Icon.IconInfoSquareRoundedFilled
													color="red"
													style={{ fillRule: 'initial', color: '#6B7280' }}
													size="18"
												/>
											</Tooltip>
										</Box>
									)}
								</TFText>
							}
						>
							<TFText size="14px" sx={{ pl: '20rem' }} c="#6B7280">
								{fmt.toDate(event.createdAt, { preset: 'full_date_with_time' })}
							</TFText>
						</Timeline.Item>
					);
				})}
			</Timeline>
		</Box>
	);
};

const getTimelineData = (
	kind: BackendTypes.EntityKind,
	users: BackendTypes.UserInfo[],
	authorId?: string
) => {
	const endOnlyClientEvents = ['CLIENT_CASE_REQUEST_VIEWED', 'CLIENT_CASE_REQUEST_UPDATED'];
	if (endOnlyClientEvents.includes(kind)) {
		return {
			icon: (
				<Avatar radius="xl" size="28" color="brand">
					<Icon.IconUser size="18" />
				</Avatar>
			),
			name: 'End client',
			userExists: true,
		};
	}

	if (authorId?.includes('_ROBOT')) {
		return {
			icon: (
				<Avatar radius="xl" size="28" styles={{ root: { backgroundColor: '#000000' } }}>
					<TFLogo size={18} />
				</Avatar>
			),
			name: 'TrustForm',
			userExists: true,
		};
	}

	const user = users.find((u) => u.userId === authorId);
	if (user) {
		const fullName = `${user.firstName} ${user.lastName}`.trim();
		return {
			icon: (
				<Avatar radius="xl" size="28" color="brand">
					{S.abbreviate(fullName)}
				</Avatar>
			),
			name: getDisplayName(user),
			userExists: true,
		};
	}

	return {
		icon: (
			<Avatar radius="xl" size="28" color="brand">
				<Icon.IconSpy size="18" />
			</Avatar>
		),
		name: 'Unknown user',
		userExists: false,
	};
};

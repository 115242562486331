import React, { useEffect } from 'react';

import { useAuth } from '@clerk/clerk-react';
import { observer, useSelector } from '@datagrid/state';
import { useQuery } from '@tanstack/react-query';

import { BackendNoAuthClient, type BackendTypes, CSClient, TDMClient } from '@tf/api';
import { TFLoadingOverlay } from '@tf/ui';

import { useGetTenantToken } from '@/core/hooks';
import { getDefinitionsQuery, getProcessingScriptsQuery, getTenantConfigQuery } from '@/core/queries';
import type { AppStoreType } from '@/core/stores';
import { appStore, configStore } from '@/core/stores';

import { BackendClientInitialization } from './BackendClientInitialization';
import { applyVisualModifierPatches } from './visualModifiers';

const useInitialDataQuery = () => {
	const config = useSelector(() => configStore.get());
	const getTenantToken = useGetTenantToken();

	return useQuery({
		queryKey: ['initialData'],
		enabled: false,
		queryFn: async () => {
			TDMClient.init(config.urls.tdm);
			CSClient.init(config.urls.cs);
			BackendNoAuthClient.init(config.urls.backend);

			const token = await getTenantToken();
			if (!token) {
				appStore.set((prev: AppStoreType) => ({
					...prev,
					state: 'no_permissions',
				}));
				return null;
			}

			const tenantConfig = await getTenantConfigQuery(token.tenant);

			const [appMeta, defs, processingScripts] = await Promise.all([
				BackendNoAuthClient.getInstance()
					.appMeta({
						headers: {
							Authorization: `Bearer ${token?.value}`,
							'Configuration-Revision': tenantConfig.revision,
						},
					})
					.then((res: any) => res.json()) as Promise<BackendTypes.AppMetaData>,
				getDefinitionsQuery(tenantConfig.revision),
				getProcessingScriptsQuery(tenantConfig.revision),
			]);

			const modifiedDefs = applyVisualModifierPatches(defs);

			return {
				tenant: token.tenant,
				currency: tenantConfig.currency,
				appMeta,
				defs: modifiedDefs,
				defsRevision: tenantConfig.revision,
				processingScripts,
			};
		},
	});
};

export const StateProvider: React.FC<React.PropsWithChildren> = observer(function StateProvider({
	children,
}) {
	const { isSignedIn } = useAuth();
	const initialDataQuery = useInitialDataQuery();

	useEffect(() => {
		if (isSignedIn) {
			initialDataQuery.refetch().then((result) => {
				if (!result.data) {
					return;
				}

				const { tenant, appMeta, defs, processingScripts, currency } = result.data;

				appStore.set((prev) => ({
					...prev,
					state: 'authorized',
					appMeta,
					tenant,
					currency,
					defs,
					processingScripts,
				}));
			});
		} else {
			appStore.set((prev) => ({ ...prev, state: 'unauthorized' }));
		}
		// Extra deps cause more refetches
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSignedIn]);

	if (!isSignedIn) {
		return <>{children}</>;
	}

	if (initialDataQuery.status === 'error') {
		return <div>Error, please reload the page</div>;
	}

	if (initialDataQuery.isPending) {
		return <TFLoadingOverlay />;
	}

	return (
		<>
			<BackendClientInitialization definitionsRevision={initialDataQuery.data?.defsRevision ?? ''} />

			{children}
		</>
	);
});

import React, { useEffect, useState } from 'react';

import { SignUp, useAuth, useClerk } from '@clerk/clerk-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { TFErrorTemplate, useSignInWithTicket } from '@tf/shared';
import { Anchor, Center, TFLoadingOverlay } from '@tf/ui';

export const AcceptInvitationRoute: React.FC = () => {
	const [params] = useSearchParams();
	const clerkStatus = params.get('__clerk_status');
	const clerkTicket = params.get('__clerk_ticket');

	const [state, setState] = useState<'sign_in' | 'sign_up' | 'error'>(
		!!clerkTicket && (clerkStatus === 'sign_in' || clerkStatus === 'sign_up') ? clerkStatus : 'error'
	);

	const navigate = useNavigate();
	const signInWithTicket = useSignInWithTicket();
	const { signOut } = useClerk();
	const { isSignedIn } = useAuth();

	useEffect(() => {
		const logout = async () => {
			if (isSignedIn) {
				await signOut({ redirectUrl: window.location.href });
			}
		};
		logout();
	}, [isSignedIn]);

	useEffect(() => {
		if (!clerkTicket || state !== 'sign_in' || !signInWithTicket) {
			return;
		}

		signInWithTicket(clerkTicket)
			.then(() => {
				navigate('/');
			})
			.catch(() => {
				setState('error');
			});
	}, [clerkTicket, navigate, signInWithTicket, state]);

	if (state === 'sign_in' || isSignedIn) {
		return <TFLoadingOverlay />;
	}

	if (state === 'sign_up') {
		return (
			<Center mt="20vh">
				<SignUp routing="path" path="/accept-invite" signInForceRedirectUrl={window.location.origin} />
			</Center>
		);
	}

	return (
		<TFErrorTemplate
			title="Error accepting invitation"
			text="Try to sign in or ask your administrator to send an invitation again"
		>
			<Anchor component={Link} to="/sign-in">
				Go to Sign in
			</Anchor>
		</TFErrorTemplate>
	);
};
